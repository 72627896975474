<template>
    <div class=" w-100 flex-1" ref="monacoEditorContainer"></div>
</template>
<script setup>
import { ref, onMounted, computed, onUpdated, onUnmounted, onBeforeUnmount } from 'vue';
import MonacoControl, { monaco } from 'o365.controls.MonacoControl.ts';
const monacoEditorContainer = ref(null);

const props = defineProps({
    primKey: {
        required: false
    },
    readonly: {
        required: false,
        type: Boolean,
        default: false
    },
    modelValue: {
        required: false,
        type: String
    },
    fileName: {
        required: false,
        type: String
    },
    lang: {
        required: false,
        type: String
    },
    dataObject: {
        required: false
    },
    keepModelsAfterDispose: {
        type: Boolean,
        default: false
    },
    formatJSON: {
        type: Boolean,
        default: false
    }
});


const emit = defineEmits(['update:modelValue']);

let control = null;
let initiated = false;
let item = null;

onMounted(() => {
    initializeMonaco();
})

onUpdated(() => {
    initializeMonaco();
})

onBeforeUnmount(() => {
    initiated = false;
    control.dispose();
})

const initializeMonaco = async () => {
    if (control == null) {
        control = new MonacoControl({
            element: monacoEditorContainer.value,
            onSave: props.dataObject ? save : null,
            onDidChangeModelContent: onDidChangeModelContent,
        })

        await control.initMonaco();
    }

    const fileName = props.fileName ?? props.primKey;
    const key = props.primKey ?? props.fileName;

    control.setCurrentModel(key, props.lang == 'json' ? formatJSON(props.modelValue) : props.modelValue, props.lang, fileName);
}

const getControl = () => {
    control['monaco'] = monaco;
    return control;
}

async function save() {
    let doSave = true;
    if (props.dataObject) {
        await props.dataObject.save();
    }
}

function formatJSON(pValue) {
    try {
        let vValue = JSON.parse(pValue);
        return JSON.stringify(vValue, null, 4)
    } catch {
        return JSON.stringify(pValue, null, 4);
    }
}

function onDidChangeModelContent(val) {
    emit('update:modelValue', control.editor.getModel().getValue());
}

defineExpose({ getControl });

</script>